


import React from "react"
import SEO from "../components/Layout/SEO"
import Login from "../components/Login"
import BareLayout from "../components/Layout/BareLayout"

const LoginPage = () => (
  <BareLayout>
    <SEO title="Log into the world's best herb encyclopedia" />
    <Login />
  </BareLayout>
)

export default LoginPage
