import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import { loginUser } from "../../services/user.services"
import AuthUserContext from "../../providers/AuthProvider"
import ErrorsContext from "../../providers/ErrorsProvider"
import {
  LANDING,
  REGISTER,
} from "../Layout/routes"
import { PasswordResetContainer } from "../Layout/BareLayout"
import { Logo } from "../Layout/GlobalStyle"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import withLocation from "../common/withLocation"
import { navigate } from "@reach/router"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}))

function Login(props) {
  const [loading, setLoading] = useState(false)
  const { setErrors, errors, clearErrors } = useContext(ErrorsContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [message, setMessage] = useState("")
  const context = useContext(AuthUserContext)
  const classes = useStyles()

  const handleNavigation = async decoded => {
    let { management, search } = props
    let { redirect = false } = search

    let { role, client_information } = decoded
    if (redirect) {
      // Router.push(`${redirect}`)
      await navigate(redirect)
    } else {
      await navigate("/")
    }
  }

  const handleKeyDown = e => {
    let { keyCode } = e
    if (keyCode === 13) {
      handleSubmit()
    }
  }

  function handleSubmit() {
    setLoading(true)
    let { setUser, setReady } = context
    loginUser({ email, password })
      .then(decoded => {
        setUser(decoded)
        setReady(true)
        handleNavigation(decoded)
      })
      .catch(err => {
        setLoading(false)
        if (err.response.data) {
          setErrors(err.response.data)
        }
      })
  }

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      let localEmail = localStorage.getItem("email")
      let localPassword = localStorage.getItem("password")

      setEmail(localEmail)
      setPassword(localPassword)
    }

    return () => (isSubscribed = false)
  }, [])

  return (
    <>
      <PasswordResetContainer>
        <div className="wrapper">
          <div className="company-logo">
            <Link href={LANDING}>
              <a>
                <Logo />
              </a>
            </Link>
          </div>

          <div className="wrapper-content">
            <div style={{ textAlign: "left" }} className="column-title">
              Login
            </div>
            <p className="column-description">
              Log into the world's best herb encyclopedia
            </p>

            <div data-private className="input-wrapper">
              <TextField
                disabled={loading}
                size="small"
                value={email}
                onChange={e => setEmail(e.target.value)}
                helperText={errors.email ? errors.email : ""}
                error={errors.email ? true : false}
                id="emailFormInput"
                label="Email"
                variant="outlined"
              />
              <TextField
                disabled={loading}
                size="small"
                type="password"
                onKeyUp={e => handleKeyDown(e)}
                value={password}
                onChange={e => setPassword(e.target.value)}
                helperText={errors.password ? errors.password : ""}
                error={errors.password ? true : false}
                id="passwordFormInput"
                label="Password"
                variant="outlined"
              />
            </div>
            <br />
            <Button
              disableElevation
              variant={"contained"}
              color="secondary"
              disabled={loading}
              fullWidth
              onClick={() => handleSubmit()}
            >
              {loading ? "Logging in" : "Login"}
            </Button>

            <div className="new-to">
              <span />
              <h5>New to FindAnHerb?</h5>
              <span />
            </div>
            <Link to={REGISTER}>
              <Button
                disableElevation
                variant={"contained"}
                color="secondary"
                disabled={loading}
                fullWidth
                onClick={() => handleSubmit()}
              >
                Create An Account
              </Button>
            </Link>
          </div>
          {message ? (
            <>
              <p className="query-message">{message}</p>
            </>
          ) : (
            ""
          )}
        </div>
      </PasswordResetContainer>
    </>
  )
}

export default withLocation(Login)
